<template>
	<div class="Menu" :class="{ 'Menu_open': open }">
		<div class="Menu__content">
			<div class="Menu__title">Меню</div>
			<div class="Menu__list">
				<RouterLink v-for="(video, vKey) in Object.values(gallery)" :key="vKey" class="Menu__list-item" :to="`/${video.slug}`" @click.native="toggle()">
					<div class="item-cover"><img :src="video.poster || `/video-placeholder.jpg`" /></div>
					<div class="item-text">{{ video.name }}</div>
				</RouterLink>
			</div>
		</div>
		<div class="Menu__open-btn" @click="toggle()">{{ open ? 'Закрыть' : 'Меню' }}</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			open: false,
			gallery: {},
		};
	},
	mounted() {
		this.loadGallery();
	},
	methods: {
		toggle() {
			this.open = !this.open;
		},
		loadGallery() {
			fetch('/get.php')
				.then((result) => result.json())
				.then((result) => {
					this.gallery = result;
				});
		},
	},
};
</script>

<style lang="scss">
.Menu {
	width: 70%;
	height: 100%;
	padding: 10rem 8rem;
	position: absolute;
	top: 0; left: 0;
	background: rgba(0, 0, 0, 0.7);
	backdrop-filter: blur(10px);
	transition:
		transform 0.7s ease;
	transform: translateX(-100%);

	.Menu__content {
		max-height: 100%;
		display: flex;
		flex-direction: column;
		transition:
				transform 0.3s ease 0s,
				opacity 0.3s ease 0s;
		transform: translateX(-30%);
		opacity: 0;

		.Menu__title {
			margin-bottom: 4rem;
			font-size: 8rem;
		}

		.Menu__list {
			padding-right: 3rem;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 3rem;
			overflow-x: hidden;
			overflow-y: scroll;

			.Menu__list-item {
				display: flex;
				flex-direction: column;
				grid-gap: 2rem;

				.item-cover {
					width: 100%;
					padding-top: 56.25%;
					position: relative;

					img {
						width: 100%;
						min-width: 100%;
						height: 100%;
						min-height: 100%;
						position: absolute;
						top: 0; left: 0;
						object-fit: cover;
					}
				}
				.item-text {
					height: 4rem;
				}
			}
		}
	}

	.Menu__open-btn {
		height: 4rem;
		padding: 0 2rem 0.5rem;
		position: absolute;
		top: 2rem; left: 100%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		line-height: 0.8;
		background: var(--gr-accent);
		box-shadow: var(--sh-element);
		cursor: pointer;
		transition:
				transform 0.7s ease;
		transform: translateX(0rem);
	}

	&.Menu_open {
		transform: translateX(0%);

		.Menu__content {
			transition:
					transform 0.3s ease 0.4s,
					opacity 0.3s ease 0.4s;
			transform: translateX(0%);
			opacity: 1;
		}

		.Menu__open-btn {
			transform: translateX(-2rem);
		}
	}
}
</style>
