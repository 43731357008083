import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import Application from './application/Application.vue';

import PageNotFound from './application/pages/error.vue';
import IndexPage from './application/pages/index.vue';

const routes = [
	{
		path: 		'/:pathMatch(.*)*',
		component: 	PageNotFound,
	},
	{
		path: 		'/',
		component: 	IndexPage,
	},
	{
		path: 		'/:id',
		component: 	IndexPage,
	},
	// {
	// 	path: 		'/tree',
	// 	component: 	TreePage,
	// },
	// {
	// 	path: 		'/value/:id',
	// 	component: 	ValuePage,
	// },
];

const Router = createRouter({
	history: createWebHashHistory(), routes,
});

createApp(Application)
	.use(Router)
	.mount('#application');
